<template>
    <div class="relative bottom-0 bg-light w-full">
        <div class="max-w-7xl mx-auto py-6 px-2 sm:px-6 lg:px-8">
            <div class="flex-1 text-dark text-center selection:bg-myyellow font-text">
                <p class="mb-2">
                    Made with
                    <a href="https://vuejs.org" target="_blank" title="Vue.js">
                        <font-awesome-icon :icon="['fab', 'vuejs']"/>
                    </a>
                </p>
                <p>
                    <font-awesome-icon :icon="['far', 'copyright']"/>
                    Simon Klein 2023
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
